/**
 * Typy akcji jakie mozemy wykonac na swini w danym cyklu
 * @constant {object}
 */
export const CycleActions = {
    USG_BEFORE_X_DAYS: 1, // Badanie USG zrobione przed uplywem x Dni
    PARTURITION_BEFORE_X_DAYS: 2, // Wyproszenie przed uplywem x dni
    PARTURITION_AFTER_X_DAYS: 3, // Porod zgloszony za pozno x dni
    FIRST_INSEMINATION_FROM_BIRTH: 4, // Data pierwszego krycia x dni od wieku - x dni
    NO_INSEMINATION_BEFORE_EV: 5, // Brak zgloszonej inseminacji a wystapi event w danym cyklu np. USG, Wyproszenie upadek prosiaka, mamka, odsad, aktywne sutki
    NO_USG_BEFORE_EV: 6, // brak zgloszonego badania usg - zgloszone w danym cyklu wyproszenie, updaek prosiaka odsad, lub aktywne sutki
    NO_PARTURITION_AFTER_EV: 7, // brak zgloszonego wyproszenia - zgloszony odsad lub upadek prosiaka
    NO_SEPARATION_AFTER_EV: 8, // brak zgloszonego odasdu - po zgloszonym wczesniej wyproszeniu
    NO_PARTURITION_AFTER_X_DAYS: 9, // brak zgloszonego porodu po uplywie x dni od a po zgloszonym qwczesniej inseminacji i nie zgloszonych eventrach, ktore zmienily by cykl lub swiad czyly o powtorce
    SEPARATION_CNT_BIGGER_THAN_PARTURITION_CNT: 10, // ilosc odsadzonych prosiat wieksza niz porod - upadki + mamka
    SEPARATION_CNT_BIGGER_THAN_BIRTH_CNT: 11, // ilosc odsadzonych do mamki wieksza niz ilosc urodzonych - upadki do momentu zgloszenia
    PIGLET_CNT_BIGGER_THAN_NIPPLES: 12, // ilosc prosiakow wieksza niz aktywne sutki,
    NO_SEPARATION_BUT_SOW_CYCLES: 13, // brak wprowadzonej separacji, lecz istnieje data ostatniego odsadu
    LAST_SEPARATION_DIFF_THAN_IN_REALITY: 14 // data ostatniej separacji na sow cycles inna niż faktycznie zgłoszonego odsadu
};
