export const NotificationStatus = {
    INFO: "INFO",
    WARN: "WARN",
    ALERT: "ALERT"
};

export const NotificationTypes = {
    PLACE: "Place",
    NO_INSEMINATION: "NoInsemination",
    TOO_FAST_BIRTH: "TooFastBirth",
    SEPARATION_WRONG_AMOUNT: "SeparationWrongAmount",
    TOO_FAST_INSEMINATION: "TooFastInsemination",
    DEVICE_ALARM: "DeviceAlarm",
    ANIMAL: "Animal",
    TREATMENT: "Treatment",
    USG_NO_INSEMINATION: "USGNoInsemination",
    BIRTH_NO_PREGNANCY: "BirthNoPregnancy",
    BIRTH_NEGATIVE_USG: "BirthNegativeUSG",
    FALL_PIGLETS_WRONG_AMOUNT: "FallPigletsWrongAmount",
    MOMMY_WRONG_AMOUNT: "MommyWrongAmount",
    AUTO_CREATE_ANIMAL: "AutoCreateAnimal",
    NO_PREGNANCY_NO_INSEMINATION: "NoPregnancyNoInsemination",
    REPORT_GENERATED: "RaportGenerated"
};