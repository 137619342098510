
const defaultRegex = /^\b(0x[0-9a-fA-F]+|[0-9]+)(?: *- *\b(0x[0-9a-fA-F]+|[0-9]+))?(?: *, *\b(0x[0-9a-fA-F]+|[0-9]+)(?: *- *\b(0x[0-9a-fA-F]+|[0-9]+))?)*$/;
const number1to16Regex = /^\b(0x([0-9]|[a-fA-F])|([1-9]|1[0-5]))(?: *- *\b(0x([0-9]|[a-fA-F])|([1-9]|1[0-5])))?(?: *, *\b(0x([0-9]|[a-fA-F])|([1-9]|1[0-5]))(?: *- *\b(0x([0-9]|[a-fA-F])|([1-9]|1[0-5])))?)*$/;
// const number1to8Regex = /^\b(0x(0*[1-8])|([1-8]))(?: *- *\b(0x(0*[1-8])|([1-8])))?(?: *, *\b(0x(0*[1-8])|([1-8]))(?: *- *\b(0x(0*[1-8])|([1-8])))?)*$/;
const wstRegex = /^\b(0x([1-7]?[0-9a-fA-F])|([1-9]|[1-8][0-9]|9[0-9]|1[01][0-9]|12[0-7]))(?: *- *\b(0x([1-7]?[0-9a-fA-F])|([1-9]|[1-8][0-9]|9[0-9]|1[01][0-9]|12[0-7])))?(?: *, *\b(0x([1-7]?[0-9a-fA-F])|([1-9]|[1-8][0-9]|9[0-9]|1[01][0-9]|12[0-7]))(?: *- *\b(0x([1-7]?[0-9a-fA-F])|([1-9]|[1-8][0-9]|9[0-9]|1[01][0-9]|12[0-7])))?)*$/;
const nrfDiRegex = /^\b(0x[1-9a-fA-F]{1}[3579BDFbdf]{1})(0[1-9a-fA-F])(?: *- *\b(0x[1-9a-fA-F]{1}[3579BDFbdf]{1})(0[1-9a-fA-F]))?(?: *, *\b(0x[1-9a-fA-F]{1}[3579BDFbdf]{1})(0[1-9a-fA-F])(?: *- *\b(0x[1-9a-fA-F]{1}[3579BDFbdf]{1})(0[1-9a-fA-F]))?)*$/;
const nrfMultiRegex = /^(0x[1-9a-fA-F]{1}[3579BDFbdf]{1})(00)(?: *- *\b(0x[1-9a-fA-F]{1}[3579BDFbdf]{1})(00))?(?: *, *\b(0x[1-9a-fA-F]{1}[3579BDFbdf]{1})(00)(?: *- *\b(0x[1-9a-fA-F]{1}[3579BDFbdf]{1})(0[0]))?)*$/;

export const FORMAT = {
    NUMBER_DEC_OR_HEX: /^\b(0x[0-9a-fA-F]+|[0-9]+)*$/,
    DEVICE_MANY_ADDR: defaultRegex,
    DEVICE_MANY_ADDR_DI_NRF: nrfDiRegex,
    DEVICE_MANY_ADDR_CA: wstRegex,
    DEVICE_MANY_ADDR_DI: wstRegex,
    DEVICE_MANY_ADDR_SC: wstRegex,
    DEVICE_MANY_ADDR_CL: wstRegex,
    DEVICE_MANY_ADDR_BR: number1to16Regex,
    DEVICE_MANY_ADDR_TE: wstRegex,
    DEVICE_MANY_ADDR_DI_NRF_MULTI: nrfMultiRegex,
    DEVICE_MANY_ADDR_ANT: number1to16Regex,
    DEVICE_MANY_ADDR_SP: nrfMultiRegex
};