export const DispenserViewErrors = {
    RFID_NOT_FOUND_IN_SYSTEM: 0,
    WRONG_ANIMAL_LOCATION: 1,
    DISPENSER_RUNS_ON_DEFAULT_CURVE: 2,
    NO_DEVICE_ASSIGNED_TO_STANDING: 3,
    TOO_MANY_DEVICES_ASSIGNED_TO_STANDING: 4,
    DEVICE_WITH_GIVEN_ID_NOT_FOUND: 5,
    NO_CURVE_ASSIGNED: 6,
    TOO_MANY_SOWS_ASSIGNED_TO_STANDING: 7,
    NO_DATA: 8,
    WRONG_DISPENSER_CONFIGURATION: 9,
};

export const DispenserErrorType = {
    VIEW: 0,
    MOTOR: 1000,
    FEEDING: 2000,
    CONFIG: 3000
};