export const Roles = {
    SERVICE: "SERVICE",
    OWNER: "OWNER",
    MANAGER: "MANAGER",
    DEVICE_STANDARD_READ: "DEVICE_STANDARD_READ",
    DEVICE_STANDARD_WRITE: "DEVICE_STANDARD_WRITE",
    DEVICE_DI_READ: "DEVICE_DI_READ",
    DEVICE_DI_WRITE: "DEVICE_DI_WRITE",
    ANIMAL_READ: "ANIMAL_READ",
    ANIMAL_WRITE: "ANIMAL_WRITE",
    GENERAL_READ: "GENERAL_READ",
    GENERAL_WRITE: "GENERAL_WRITE",
    SUBSCRIBE: "SUBSCRIPTION",
    _SUBSCRIPTION: "_SUBSCRIPTION",
    _SERVICE: "_SERVICE",
    _TRANSLATION: "_TRANSLATION",
    _REPORT_WRITE: "_REPORT_WRITE",
    _REPORT_READ: "_REPORT_READ",
    _GENERAL_READ: "_GENERAL_READ",
    _DEVICE_SILO: "_DEVICE_SILO",
    _DEVICE_FEEDING: "_DEVICE_FEEDING",
    _DEVICE_CLIMATE: "_DEVICE_CLIMATE",
    _DEVICE_CHAIN: "_DEVICE_CHAIN",
    _DEVICE_CAGE: "_DEVICE_CAGE",
    _BUILDING: "_BUILDING",
    _BREEDING: "_BREEDING",
    _BILLING: "_BILLING",
    _USER_MANAGE: "_USER_MANAGE",
    _DEVICE_PIGLET_CAGE: "_DEVICE_PIGLET_CAGE",
    _DEVICE_COUNTER: "_DEVICE_COUNTER",
    _DEVICE_CONFIG: "_DEVICE_CONFIG"
};

/**
 * Nowe role
 * @type {{REPORT_WRITE: string, REPORT_READ: string, BILLING: string, DEVICE_PIGLET_CAGE: string, DEVICE_COUNTER: string, DEVICE_FEEDING: string, GENERAL_READ: string, DEVICE_CLIMATE: string, BUILDING: string, DEVICE_CHAIN: string, BREEDING: string, DEVICE_CONFIG: string, DEVICE_CAGE: string, DEVICE_SILO: string}}
 * @private
 */
export const _Roles = {
    GENERAL_READ: "_GENERAL_READ",
    BREEDING: "_BREEDING",
    DEVICE_CAGE: "_DEVICE_CAGE",
    DEVICE_SILO: "_DEVICE_SILO",
    DEVICE_CONFIG: "_DEVICE_CONFIG",
    REPORT_WRITE: "_REPORT_WRITE",
    REPORT_READ: "_REPORT_READ",
    REPORTS: "_REPORT",
    DEVICE_FEEDING: "_DEVICE_FEEDING",
    DEVICE_CLIMATE: "_DEVICE_CLIMATE",
    DEVICE_CHAIN: "_DEVICE_CHAIN",
    BUILDING: "_BUILDING",
    BILLING: "_BILLING",
    DEVICE_PIGLET_CAGE: "_DEVICE_PIGLET_CAGE",
    DEVICE_COUNTER: "_DEVICE_COUNTER",
    USER_MANAGE: "_USER_MANAGE",
    SERVICE: "_SERVICE",
};

export const LicPackageLevel = {
    NO_ACCESS: null,
    BASIC: "BASIC",
    EXTENDED: "EXTENDED"
};

export const LicPackageKeys = {
    ADMINISTRATION: "administration",
    ALARM: "alarms",
    CAGE: "cages",
    CHAIN: "chains",
    CLIMATE: "climates",
    COUNTER: "counters",
    DISPENSER_5G: "dispensers5G",
    DISPENSER: "dispensers",
    PORKER: "porkers",
    SMALL_CAGE: "smallCages",
    SILO: "siloses",
    SOW: "sows",
};
