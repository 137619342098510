export const controlLists = {
    INSEMINATIONS: "Inseminations",
    CONDITION: "Condition",
    USG: "USG",
    SOWS_TO_INSEMINATION: "SowsToInsemination",
    SOWS_TO_TRANSFER: "SowsToTransfer",
    PLANNED_PARTURITIONS: "PlannedParturitions",
    SOWS_TO_GRAFTING: "SowsToGrafting",
    BOARS_TO_GRAFRING: "BoarsToGrafting",
    PORKER_TO_GRAFTING: "PorkerToGrafting",
    PIGLET_TO_GRAFTING: "PigletToGrafting",
    SOWS_FORAGE: "SowsForage",
    BOARS_FORAGE: "BoarsForage",
    PORKER_FORAGE: "PorkerForage",
    PIGLET_FORAGE: "PigletForage",
    SOWS_DOSATRON: "SowsDosatron",
    BOARS_DOSATRON: "BoarsDosatron",
    PORKER_DOSATRON: "PorkerDosatron",
    PIGLET_DOSATRON: "PigletDosatron",
    SOWS_STIMULATOR: "SowsStimulators",
    REMANENT: "Remanent"
};

export const controlListsPeriods = {
    X_DAYS: "0",
    END_OF_MONTH: "1",
    TO_DAY_IN_MONTH: "2",
    RANGE: "3"
}