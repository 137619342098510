import React, {Component} from "react";
import {withRouter} from "react-router-dom";
import Routes from "../route/Routes";
import Loadable from 'react-loadable';
import {connect} from "react-redux"
import {fetchLanguage, getLanguages} from "../actions/localizationActions";
import {redirect} from "../actions/historyActions";
import {withCookies} from 'react-cookie';
import {hide, show} from "redux-modal";
import {bindActionCreators} from "redux";
import "bootstrap/dist/css/bootstrap.min.css"
import '../resources/scss/_style.scss';
import {DataLoader} from "../components/data-loader/DataLoader";
import {Container} from "react-bootstrap"
import ConfirmModal from "../components/modals-new/confirm-modal/ConfirmModal";
import LoadingComponent from "../components/loading/LoadingComponent";
import {listDocumentsDynamoDB} from "../actions/documentsActions";
import _ from "lodash";
import {Roles} from "../constans/roles";
import FarmExpirationStatus from "../components/farm-expiration-status/FarmExpirationStatus";
import {initialize} from "redux-form";
import {getRFIDTagReader} from "../utils/SettingsUtils";
import TagReaderDrawer from "../components/tag-reader-drawer/TagReaderDrawer";
import queryString from "query-string";
import {logout} from "../utils/UserUtils";
import {withTranslation} from "react-i18next";
import {checkMaintenanceStatus} from "../actions/serviceActions";
import TechnicalBreakComponent from "../components/technical-break-component/TechnicalBreakComponent";
import {pushRefreshTokenAndDeviceKeyToServiceWorker} from "../utils/ServiceWorkerUtils";
import {detect} from "detect-browser";

const Loading = () => <div/>;
const AsyncLeftSideBar = Loadable({
    loader: () => import("../components/side-bars/LeftSideBar"),
    loading: Loading
});
const AsyncCustomNotificationsSystem = Loadable({
    loader: () => import("../components/notifications-system/CustomNotificationsSystem"),
    loading: Loading
});

@connect((store) => {
    return {
        user: store.user,
        path: store.history.path,
        documents: store.documents.documents,
        doRedirect: store.history.haveToRedirect,
        view: store.view.viewProperties,
        farm: store.location.farm,
        globalLoading: store.view.globalLoading,
        general: store.settings.general,
        maintenance: store.maintenance
    };
})
class App extends Component {

    constructor(props) {
        super(props);
        this.setUserLang();
        props.dispatch(checkMaintenanceStatus());
    }

    setUserLang = () => {
        const {dispatch, i18n} = this.props;
        let userLang = 'en';
        try {
            userLang = window.navigator.language || 'en'
        } catch (e) {
            userLang = 'en'
        }
        if (userLang.includes('-')) {
            userLang = userLang.split('-')[0]
        }
        if (!['en', 'pl', 'es', 'ru'].includes(userLang)) {
            userLang = 'en'
        }
        i18n.changeLanguage(userLang);
        dispatch(fetchLanguage(userLang));
    };

    UNSAFE_componentWillMount() {
        const {dispatch} = this.props;
        dispatch(listDocumentsDynamoDB());
    }

    onAddClick = (rfid) => {
        this.props.history.push(`/${this.props.farm}/eventChooser/animalKind`);
        this.props.dispatch(initialize("insertion", {
            animals: [{rfid}],
            rfids: [{rfid}],
            assignRFID: true
        }))
    }

    componentDidMount() {
        const {dispatch, user: {isAuthenticated}, path, location} = this.props;
        if (location.pathname === "/confirm") {
            let queryParams = queryString.parse(location.search);
            dispatch(redirect(`/confirm/${queryParams.code}/${queryParams.username}`));
        }
        dispatch(getLanguages());
        if (isAuthenticated) {
            dispatch(redirect(path));
        }
        let browser = detect();
        window.addEventListener("storage", e => {
            if (browser.name !== "safari") { // na safari nie dziala ten mechanizm, poniewaz safari inaczej sie zachowuje z wartosciami kluczy, kiedy sa puste
                if (e && !_.isEmpty(e.key) && e.key.includes("LastAuthUser") && e.oldValue === null) {
                    // tu sie safari wysypuje poniewaz po zalogowaniu oldValue === null, a na chrome jest inaczej
                    window.location.reload();
                }
                if (e && !_.isEmpty(e.key) && e.key.includes("LastAuthUser") && e.oldValue !== null && e.newValue !== e.oldValue) {
                    logout();
                }
            }
            if (e && !_.isEmpty(e.key) && e.key.includes("refreshToken") && e.newValue) {
                pushRefreshTokenAndDeviceKeyToServiceWorker(e.newValue);
            }
            if (e && !_.isEmpty(e.key) && e.key.includes("deviceKey") && e.newValue) {
                pushRefreshTokenAndDeviceKeyToServiceWorker(null, e.newValue);
            }
        });
        let currentUser = null;
        for (let i = 0; i < localStorage.length; i++) {
            let key = localStorage.key(i);
            if (key.includes("LastAuthUser")) {
                currentUser = localStorage.getItem(key);
                break;
            }
        }
        if (currentUser) {
            let token = null, deviceKey = null;
            for (let i = 0; i < localStorage.length; i++) {
                let key = localStorage.key(i);
                if (key.includes(`${currentUser}.refreshToken`)) {
                    token = localStorage.getItem(key);
                }
                if (key.includes(`${currentUser}.deviceKey`)) {
                    deviceKey = localStorage.getItem(key);
                }
            }
            if (token && deviceKey) {
                pushRefreshTokenAndDeviceKeyToServiceWorker(token, deviceKey);
            }
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const {history, doRedirect, dispatch, path} = nextProps;
        console.log(nextProps);
        if (doRedirect) {
            history.push(path);
            dispatch({type: "REDIRECT_END"});
        }

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {maintenance, dispatch} = this.props;
        if (maintenance.inMaintenance && !_.isEqual(maintenance, prevProps.maintenance)) {
            dispatch(redirect("/maintenance"));
        }
    }

    render() {
        const {user: {isChangingClientID, isAuthenticated, isAuthenticating, user}, farm: FarmID, view: {left: showLeftbar}, globalLoading, farm, documents = [], maintenance: maintenanceObject} = this.props;
        let blockLoadingData = !user;
        let read = _.get(user, 'DocRead', []);
        let blockingDocs = documents.filter(d => d.BlockAccess).map(d => d.DocID);
        blockingDocs.forEach(d => {
            if (!read.includes(d)) {
                blockLoadingData = true;
            }
        });
        if (!blockLoadingData) {
            blockLoadingData = !_.get(user, "Roles", []).filter(r => r.Role !== Roles._SUBSCRIPTION).length
        }
        let maintenance = maintenanceObject.inMaintenance;
        return (
            <React.Fragment>
                <div className={"App"}>
                    <Container fluid={true}>
                        <LoadingComponent
                            style={{position: 'fixed', top: 0, left: 0, right: 0, bottom: 0, zIndex: 2137}}
                            isLoading={isChangingClientID || isAuthenticating || globalLoading}/>
                        <div className={"error-main-container"}>
                            {
                                !isChangingClientID && !maintenance && isAuthenticated &&
                                <>
                                    {
                                        !blockLoadingData &&
                                        <DataLoader/>
                                    }
                                </>
                            }
                        </div>
                        {
                            !isChangingClientID && !maintenance && isAuthenticated && showLeftbar && farm &&
                            <AsyncLeftSideBar/>
                        }
                        <div className={"content"} id="center-content">
                            <TechnicalBreakComponent/>
                            <FarmExpirationStatus/>
                            {
                                !isChangingClientID &&
                                <Routes childProps={{FarmID, isAuthenticated, isAuthenticating}}/>
                            }
                        </div>
                        {
                            !maintenance && isAuthenticated && getRFIDTagReader() && <TagReaderDrawer/>
                        }
                    </Container>
                </div>
                {
                    !maintenance && isAuthenticated && <AsyncCustomNotificationsSystem/>
                }
                <ConfirmModal/>
            </React.Fragment>
        );
    }

}

App = connect(null, dispatch => bindActionCreators({show, hide}, dispatch))(App);
export const _App = App;
App = withRouter(App);
App = withCookies(App);
App = withTranslation()(App);
export default App;
// export default connect(mapStateToProps)(App)
