export const eventChooserFormNames = {
    PORKER_AND_PIGLET_GROUP_INSERTION: "porkerAndPigletGroupInsertion",
    PORKER_AND_PIGLET_RFID_INSERTION: "porkerAndPigletRFIDInsertion",
    RENOVATION_SOW_AND_BOAR_INSERTION: "renovationSowAndBoarInsert",
    SOW_INSERTION: "sowInsertion",
    INSEMINATION: "insemination",
    USG: "usg",
    NO_PREGNANCY: "noPregnancy",
    PARTURITION: "parturition",
    SEPARATION_TO_PORKHOUSE: "separationToPigletHouse",
    SEPARATION_TO_MOMMY: "separationToMommy",
    TREATMENT: "treatment",
    GRAFTING: "grafting",
    FALL_PIGLETS: "fallPiglets",
    TRANSFER_CHAMBER: "transferChamber",
    TRANSFER_STAND: "transferStand",
    FALL: "fall",
    SELL: "sell",
    SELECTION: "selection",
    WEIGHTING: "weighting",
    ACTIVE_NIPPLES: "activeNipples",
    RECLASSIFY: "reclassify",
    PIGLETS_TREATMENT: "pigletsTreatment",
    HEAT: "heat"
}