export const reportStatus = {
    LOADING: "RUNNING",
    READY: "SUCCEEDED",
    FAILED: "FAILED",
    QUERY_EXECUTION_NOT_STARTED_FATAL: "QUERY_EXECUTION_NOT_STARTED_FATAL",
    INVALID_QUERY_PARAMETERS: "INVALID_QUERY_PARAMETERS",
    CANCELLED: "CANCELLED",
    REMOVING: "REMOVING"
};

export const reportType = {
    BIRTH: "birth",
    IDLE: "idle",
    INSEMINATION: "insemination",
    SEPARATION: "separation",
    STRUCTURE: "structure",
    SELECTION: "selection",
    DEAD: "dead",
    SALES: "sale",
    TREATMENT: "treatment",
    MEDICINE_CONSUMPTION: "medicineConsumption",
    DISPENSER_USAGE_Q03: "Q03_sumoffeedings_by_devid",
    DISPENSER_USAGE_Q49: "Q49_forage_use_per_device",
    DISPENSER_USAGE_Q51: "Q51_feedings_for_animalid"
}