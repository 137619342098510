/**
 *
 * @type {{SOW: maciora_0, PIG: prosiak_1, PIGLET: warchlak_2, PORKER: tucznik_3, BOAR: knur_4, RENOVATION_SOW: loszka_remontowa_5}}
 */
export const AnimalTypes = {
    SOW: 0,
    PIG: 1,
    PIGLET: 2,
    PORKER: 3,
    BOAR: 4,
    RENOVATION_SOW: 5
};