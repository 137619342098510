export const TreeTypes = {
    RASPBERRY: "raspberry",
    DEVICE: "device",
    BRIDGE: "bridge",
    INTERFACE: "interface",
    NRF_GROUP: "nrfGroup",
    DEVICE_GW: "device_gw",
    BRIDGE_GW: "device_gw"
};

export const TreeColors = {
    ERROR: "#f44b4b",
    GATEWAY: "#575C55",
    BRIDGE: "#A7A5C6",
    INTERFACE: "#8797B2",
    CLIMATE: "#4A9BD4",
    CLIMATE_NEW: "#2E86AB",
    SCALE: "#F6F5AE",
    CAGE: "#359D3C",
    DISPENSER: "#564138",
    DISPENSER_NRF: "#46351D",
    NOT_IMPORTED: "#CECFC7"
};