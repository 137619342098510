// export const DEV_TYPE_CAGE = "2";
// export const DEV_TYPE_CLIMATE = "3";
// export const DEV_TYPE_SCALE = "1";
// export const DEV_TYPE_FEEDER = "4";


export const DevType = {
    GATEWAY: "GW",
    BRIDGE: "BR",
    SCALE: "SC",
    CAGE: "CA",
    CLIMATE: "CL",
    CLIMATE_SK3: "CL_SK3",
    DISPENSER: "DI",
    DISPENSER_NRF: "DI_NRF",
    DISPENSER_NRF_MULTI: "DI_MULTI",
    BRIDGE_WORK: "BR_WORK",
    BRIDGE_CONF: "BR_CONF",
    DI_TIME: "DI_TIME",
    BROADCAST: "BROADCAST",
    THERMOEYE: "TE",
    ANTENNA_RFID: "ANT",
    WATER_FLOW_METER: "MBUS_WATER",
    ELECTRICITY_FLOW_METER: "MBUS_ELECTRIC",
    CHAIN_FEEDING: "SP",
    SMALL_CAGE: "PS"
};

export const DispenserTypes = {
    DISPENSER_DTM: "DTM",
    DISPENSER_NRF: "NRF",
    ALL: "ALL" //dochodzi jeszcze liczba od numeru grupy
};

export const DispenserStatus = {
    STOP: 0, //wyłączony dozownik przez holdOut
    PAUSE: 1, //zablokowane karmienie
    ACTIVE: 2, //aktywne karmienie
    NOT_CONNECTED: 3, //dozownik niepodłaczony
    ALERTED: 4,
    NOT_FEED: 5
};

export const DispenserPercents = [{label: "-25%", value: -25}, {label: "-20%", value: -20}, {
    label: "-15%",
    value: -15
}, {label: "-10%", value: -10}, {label: "-5%", value: -5}, {label: "0%", value: 0}, {
    label: "5%",
    value: 5
}, {label: "10%", value: 10}, {label: "15%", value: 15}, {label: "20%", value: 20}, {label: "25%", value: 25}];

export const Interfaces = {
    RS: 0,
    CAN_0: 1,
    CAN_1: 2,
    RS485_0: 3,
    RS485_1: 4,
    NRF_0: 5,
    NRF_1: 6,
    NRF_2: 7,
    RS422: 8,
    DEBUG: 9,
    LCD: 10,
    BRIDGE_CONFIGURATION: 11,
    ERROR: 12,
    LOG: 13,
    BRIDGE_MBUS: 14,
    BRIDGE_WORK: 15
};

//WST, CAN, NRF, MODBUS, CONF_BRIDGE, ERROR_BRIDGE, LOG_BRIDGE, LCD
export const FrameTypes = {
    WST: "WST",
    CAN: "CAN",
    NRF: "NRF",
    MODBUS: "MBUS",
    BRIDGE_CONF: "BRIDGE_CONF",
    BRIDGE_WORK: "BRIDGE_WORK",
    ERROR_BRIDGE: "BERR",
    LOG_BRIDGE: "BLOG",
    LCD: "LCD",
    SLIP: "SLIP",
    RFID: "RFID",
    BRIDGE_MBUS: "BRIDGE_MBUS"
};

export const defFrameTypesForInterface = [
    undefined,//RS: 0,
    FrameTypes.CAN,//CAN_0: 1,
    FrameTypes.CAN,//CAN_1: 2,
    undefined,//RS485_0: 3,
    FrameTypes.WST,//RS485_1: 4,
    FrameTypes.NRF,//NRF_0: 5,
    FrameTypes.NRF,//NRF_1: 6,
    FrameTypes.NRF,//NRF_2: 7,
    undefined,//RS422: 8,
    undefined,//DEBUG: 9,
    FrameTypes.LCD,//LCD: 10,
    undefined,//BRIDGE_CONFIGURATION: 11,
    undefined,//ERROR: 12,
    undefined//LOG: 13,
];

export const DispenserNRFInterface = {
    LEFT: "L",
    CENTER: "C",
    RIGHT: "R"
};

export const BridgeBackendTypes = {
    NORMAL: "",
    CONFIGURATION: "_CONF"
};

export const LoadedDataTypes = {
    SHADOW: "shadow",
    DYNAMO: "dynamo",
    NO_DATA: "no_data"
};


export const CageWorkType = {
    MANUAL: 0,
    TRAINING_1: 1,
    TRAINING_2: 2,
    TRAINING_3: 3,
    SEPARATION: 4,
    EXPEDITION: 5,
};

//tryb pracy	0 - off, 1 - manual, 2 - automat, 3 - harmonogram
export const ChainFeedingWorkType = {
    INACTIVE: 0,
    MANUAL: 1,
    AUTOMATIC: 2,
    SCHEDULE: 3
}
