/**
 *                                  WAGA    |   ODLEGLOSC    |      POJEMNOSC     |
 * SMALL - wartości małe      ->  g         |   cm           |    ml
 * MEDIUM- wartości średnie   ->  kg        |   m            |    l
 * BIG   - wartości duże      ->  t         |   km           |    hl
 * @type {{BIG: string, SMALL: string, MEDIUM: string}}
 */
export const UnitTypes = {
    SMALL: 0,
    MEDIUM: 1,
    BIG: 2
};